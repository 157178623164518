<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    showing: Boolean,
    location: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({ location_id: '' });

const sendForm = () => {
    form.delete(route('staff.locations.destroy', props.location.id), {
        onSuccess: closeForm,
    });
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            {{ $t('Are you sure you want to delete this {model}?', { model: $t('location') }) }}
        </template>
        <template #button-bar>
            <Button
                @click="sendForm"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                danger
            >
                {{ $t('Delete') }}
            </Button>
            <Button white @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
